<template>
  <div id="interactive-page">
    <AppContent
      class="interactive-content"
      :isFullPage="true"
      :isFluid="false"
      :isContentContainer="true"
      :withSidebar="true">

      <div
        v-for="(category, index) in categories"
        :key="index">
        <div
          v-if="category.id === currCategory.id">
          <div
            class="default-container text-center"
            v-if="currCategory.videos.length == 0">
            <h3>Oh no</h3>
            <p>
              No video available for the <br> selected category</p>
          </div>

          <div v-else>
            <div
              class="premium-wrapper"
              v-show="disableVideo">
              <b-overlay
                class="premium-overlay"
                show
                :opacity="0.5"
                variant="dark"
                no-center
                style="border-radius: 10px !important;"
                title="Premium Content"
                @click="checkContent(category.videos[0], 'video')">
                <template #overlay>
                  <b-badge
                    class="position-absolute premium-badge"
                    variant="primary"
                    style="top: 3%; left: 3%;">
                    Premium
                  </b-badge>
                  <i class="fas fa-lock" style="color: transparent;"></i>
                </template>
                <b-img-lazy
                  class="img-fuid premium-thumbnail"
                  v-bind="videoProps"
                  :src="imgThumb"
                  alt="Video Thumbnail"
                />
              </b-overlay>
            </div>

            <div class="video-container" v-show="!disableVideo">
              <div class="wistia_responsive_padding">
                <div class="wistia_responsive_wrapper">
                  <div
                    v-if="category.videos[0]"
                    :class="'wistia_embed wistia_async_' +
                      category.videos[0].wistia_id +
                      ' videoFoam=true playlistLinks=auto'"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          
          <h4 class="title">{{ category.name }}</h4>

          <VideoCarousel
            :cards.sync="category.videos"
            @selectedVid="setSelectedVid"
          />
        </div>
      </div>
      
      <b-row>
        <b-col
          cols="6"
          lg="3"
          class="text-center p-3"
          v-for="(category, index) in categories"
          :key="index"
          @click="setCurrCategory(category)">
          <b-img-lazy
            class="img-fluid category-button"
            v-bind="mainProps"
            :src="category.thumbnail"
            :alt="category.name"
            :title="category.name"
          />
        </b-col>
        <b-col
          class="text-center p-3"
          cols="6"
          lg="3">
          <b-overlay
            class="coming-soon-overlay"
            show
            variant="dark"
            opacity="0.75"
            style="border-radius: 26px;">
            <template #overlay>
              <h4 class="text-center">Coming Soon</h4>
              <i
                class="fas fa-lock"
                style="display:none; color: transparent;"
              ></i>
            </template>
            <b-img-lazy
              class="img-fluid category-button"
              v-bind="mainProps"
              :src="events.thumbnail"
              :alt="events.name"
              :title="events.name"
            />
          </b-overlay>
        </b-col>
      </b-row>
      <br>
    </AppContent>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import LevelUpMixin from '@/mixins/LevelUpMixin';
  import PremiumMixin from '@/mixins/PremiumMixin';

  export default {
    name : 'InteractivePage',
    head : {
      title : {
        inner      : 'Interactive',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      LevelUpMixin,
      PremiumMixin,
    ],
    data() {
      return {
        disableVideo : false,
        imgThumb     : null,
        mainProps    : {
          center     : true,
          fluidGrow  : true,
          blank      : true,
          blankColor : '#00000080',
          width      : 323,
          height     : 323,
        },
        videoProps : {
          center     : true,
          fluidGrow  : true,
          blank      : true,
          blankColor : '#00000080',
          width      : 1390,
          height     : 791,
        },
        secondsWatched : 0,
        currVideo      : {},
      }
    },
    computed : {
      ...mapGetters({
        subscription : 'subscription/subscription',
        user         : 'user/user',
        categories   : 'interactive/categories',
        currCategory : 'interactive/current',
      }),
      allowedUser() {
        // let allowedUser = true;
        // if (this.user.user_type == 1)
        //   allowedUser = false;
        // else if (this.user.subscription.class_name == 'free')
        //   allowedUser = false;

        // return allowedUser;

        return true;
      },
      events() {
        return {
          name      : 'Events Calendar',
          thumbnail : require(
            '../../assets/images/interactive/events-calendar-button.png'),
        };
      },
    },
    watch : {
      currCategory(val) {
        this.setSelectedVid(val.videos[0]);
      },
    },
    components : {
      'AppContent'    : () => import('@/components/layout/AppContent'),
      'VideoCarousel' : () => import('./VideoCarousel'),
    },
    methods : {

      /**
       * Set Current Category
       */
      setCurrCategory(category) {
        this.$store.dispatch('interactive/setCurrentCategory', category);
        this.setSelectedVid(category.videos[0]);
      },

      /**
       * Selected Video
       * @param data
       */
      setSelectedVid(data) {
        this.currVideo = data;
        if (data) {
          this.disableVideo = (data.is_free === 0 && !this.allowedUser) ?
            true : false;

          if (this.disableVideo)
            this.imgThumb = data.thumbnail.replace(/200x120/g, '1370x771');
        }
      },

      /**
       * Set Category Button
       */
      setCategButton(name) {
        let button = null;

        if (name == 'Morning Energizer')
          button = this.mornBtn;
        else if (name == 'Dance Challenge')
          button = this.danceBtn;
        else if (name == 'Brain Break')
          button = this.brainBtn;

        return button;
      },

      gtag30sec(that){
        that.$gtag.event('video_30s_listen', {
          'media_id'    : that.currVideo.media_id,
          'media_title' : that.currVideo.media_title,
          'platform'    : 'website',
          'environment' : process.env.VUE_APP_ENVIRONMENT,
        })
      },
      gtagtotalsec(that){
        that.$gtag.event('video_total_listen', {
          'media_id'              : that.currVideo.media_id,
          'media_title'           : that.currVideo.media_title,
          'platform'              : 'website',
          'environment'           : process.env.VUE_APP_ENVIRONMENT,
          'music_listen_duration' : that.secondsWatched,
        })
      },
    },
    created() {
      window.addEventListener('beforeunload', () => {
        //this.$http.post('api/access/stopStream');
        if(this.secondsWatched > 0)
          this.gtagtotalsec(this);
      })
    },
    mounted() {
      const that = this;

      //create element for wistia external js
      const wistiaScript = document.createElement('script');
      wistiaScript.setAttribute(
        'src',
        'https://fast.wistia.com/assets/external/E-v1.js');
      document.head.appendChild(wistiaScript);
    
      window._wq = window._wq || [];
      
      // eslint-disable-next-line no-undef
      _wq.push({
        id        : "_all",
        onHasData : (video) => {
          video.unbind('secondchange')
            .bind('secondchange', () => {
              that.secondsWatched = video.secondsWatched();
            });
        },
        onReady : (video) => {
          //console.log("I got a handle on the video...")
          video.bind('end', function() {
            //console.log('END TRIGGERED......');
            if(that.secondsWatched >= 30)
              that.gtag30sec(that);
            that.gtagtotalsec(that);

            if (that.secondsWatched >= 60) {
              const event = (that.user.first_video === 0) ?
                'FIRST_VIDEO' : 'WATCHED_VIDEO';
              
              // Add Points to User
              that.addPoints(event);
            }
          });
          video.bind('pause', function() {
            //console.log('PAUSE VIDEO TRIGGERED.....')
            //console.log(that.secondsWatched)
          });
          video.bind('play', function() {
            //console.log('PLAY VIDEO TRIGGERED.....')
          });
          video.bind('beforereplace', function(){
            //console.log('BEFORE REPLACE TRIGGERED.....')
          });
        },
      });


      // Set initial video display
      if (this.$store.getters['interactive/current'] &&
        this.$store.getters['interactive/current'].videos.length > 0) {
        this.setSelectedVid(
          this.$store.getters['interactive/current'].videos[0]);
      }
    },
    beforeDestroy() {
      if(this.secondsWatched > 0)
        this.gtagtotalsec(this);
      this.$store.dispatch('interactive/setCurrentCategory',
                           this.categories[0]);
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #interactive-page {
    background-image : $interactive-bg;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    min-height: 100vh;
  }

  .category-button {
    &:hover {
      cursor: pointer;
    }
  }

  .video-container {
    background-color: #000;
    border: 0.6rem solid #000;
    border-radius: 10px;
    box-shadow: 5px 8px 15px rgba(0,0,0,0.8);
  }

  .wistia_responsive_padding {
    position: relative;
    padding: 56.25% 0 0 0;
  }

  .wistia_responsive_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }

  .title {
    padding: 1rem 1.5rem;
  }

  .premium-wrapper {
    background-color: #000;
    border: 0.6rem solid #000;
    border-radius: 10px;
    box-shadow: 5px 8px 15px rgba(0,0,0,0.8);
  }

  .premium-overlay {
    &:hover {
      cursor: pointer;
    }
  }

  .premium-badge {
    position: absolute;
    background-color: $primary-color !important;
    font-size: 0.9em;
  }

  .premium-thumbnail {
    width: 100%;
    height: 100%;
  }

  .default-container {
    padding: 13rem 0;
    width: 100%;
    height: auto;
    max-height: 791px;
    background-color: rgba(0,0,0,0.8);
    border: 0.6rem solid #000;
    border-radius: 10px;
    box-shadow: 5px 8px 15px rgba(0,0,0,0.8);
    p {
      -webkit-text-stroke: 0.5px #fff !important;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (max-width: $xs-max) {
    .video-container,
    .premium-wrapper,
    .default-container {
      margin-top: 2.8rem;
      border: 0.3rem solid #000;
      border-radius: 5px;
    }

    .default-container {
      padding: 3rem 0;
    }

    .title {
      padding-top: 0.75rem;
      padding-bottom: 0.5rem;
      text-align: center;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .video-container,
    .premium-wrapper,
    .default-container {
      margin-top: 4rem;
    }
    .default-container {
      padding: 5rem 0;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .video-container,
    .premium-wrapper,
    .default-container {
      margin-top: 4.5rem;
    }
    .default-container {
      padding: 6.5rem 0;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    #interactive-page {
      padding-left: 7.5rem;
    }
    .interactive-content {
      margin: 0 auto;
      width: 50em;
    }
    .video-container,
    .premium-wrapper,
    .default-container {
      margin-top: 2rem;
    }
    .default-container {
      padding: 7.5rem 0;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .interactive-content {
      width: 70em;
    }
    .default-container {
      padding: 10.75rem 0;
    }
  }

  @media only screen and (min-width: $special) {
    #interactive-page {
      padding-left: 5rem;
    }
    .interactive-content {
      width: 70em;
    }
    .default-container {
      padding: 12rem 0;
    }
  }

  @media only screen and (min-width: $xl2-min) {
    #interactive-page {
      padding-left: 8rem;
    }
    .title {
      padding: 2rem;
    }
    .interactive-content {
      margin: 0 auto;
      width: 75em;
    }
    .default-container {
      padding: 15.5rem 0;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .interactive-content {
      margin: 0 auto;
      width: 80em;
    }
    .default-container {
      padding: 17rem 0;
    }
  }
</style>